import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Header from './header';
import Footer from './footer';
// import Search from './common/search';
import MixHeader from './mixHeader';
import { getOriginPath } from '../utils/util';
import * as styles from './layout.module.scss';
import useEruda from '../hooks/useEruda';

const Layout = ({
  children,
  headerProps = {},
  hideSearch,
  pageFixedSearch,
  location,
  copywriting,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  // useEruda();
  useEffect(() => {
    // 只有在客户端渲染时才会执行
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
  }, []);
  const showHotBtn = copywriting?.common?.hot_btn?.show_paths
    ?.includes(getOriginPath(location.pathname)) || false;

  return <div>
    <MixHeader copywriting={copywriting} headerProps={headerProps} hideSearch={hideSearch} location={location} pageFixedSearch={pageFixedSearch} />
    {/* <Header
      copywriting={copywriting.header}
      id="header"
      {...headerProps}
      hideSearch={hideSearch}
      location={location} />
    <Search
      location={location}
      copywriting={copywriting.search}
      hideSearch={hideSearch}
      pageFixedSearch={pageFixedSearch}
    /> */}
    {showHotBtn
      ? <div className={styles.universitiesTips}>
        <a href={copywriting.common.hot_btn.link} target="_blank" rel="noreferrer">
          {copywriting.common.hot_btn.hot_btn_text}
          <span className={`iconfont iconarrow_right ${styles.icon}`}></span>
        </a>
      </div>
      : null}
    <div id="main" className={styles.container} style={{
      maxWidth: (location.pathname.indexOf('/workplace') > -1 || location.pathname === '/' || location.pathname === '/zh/' || location.pathname === '/en/') ? '100%' : '1200px', padding: (location.pathname.indexOf('/workplace') > -1 || location.pathname === '/' || location.pathname === '/' || location.pathname === '/zh/' || location.pathname === '/en/') ? '0' : isMobile ? '16px' : '16px 0', marginTop: location.pathname.indexOf('/workplace') > -1 ? '0' : '', marginTop: location.pathname === '/' || location.pathname === '/zh/' || location.pathname === '/en/' ? '0' : '',
    }}>
      <section>
        {children}
      </section>
    </div>
    <Footer copywriting={copywriting.footer} location={location} />
  </div>;
};
export default Layout;

// Layout.propTypes = {
//   children: PropTypes.copywriting.isRequired,
// };
