import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-plugin-intl';
import { throttle } from '../../utils/helper';
import { getKeyWord, getTopLevelPath } from '../../utils/util';
import { changeRecruitmentList, changejobkeywords } from '../../state/app';
import * as styles from './search.module.scss';
// import { Select, Input } from 'antd';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sWrap: '',
      hideSearch: false,
      showSelect: false,
      hasFixed: false,
      // scrollTop: 0,
      // searchOffsetTop: 0,
      val: '',
      value: props.copywriting.options[0].description,
      arr: [],
      index: -1,
      isMobile: false,
    };
    // this.scrollEvent = this.orderScroll.bind(this);
  }

  componentDidMount() {
    const { location, copywriting } = this.props;
    let value = copywriting.options.find((option) =>
      getTopLevelPath(option.url) === getTopLevelPath(location.pathname))?.description;
    if (!value) value = copywriting.options[0].description;
    this.setState({ value });
    // document.body.scrollTop = 0;
    // 生命周期，在组件加载完成后，让input聚焦 (focus)
    if (this.props.hideSearch) return;

    const initWd = getKeyWord(this.props, 'wd') || this.props.jobkeywords;
    if (initWd) {
      this.setState({ val: initWd });
    }
    window.scrollTo(0, 0);
    // this.input.focus();
    // window.addEventListener('scroll', throttle(this.orderScroll, 500));
    this.setState({ isMobile: window?.innerWidth < 768 });
    window.addEventListener('resize', this.setState({ isMobile: window?.innerWidth < 768 }));
  }

  componentWillReceiveProps(nextProps) {
    const { jobadCategory } = nextProps;
    const url = this.props.location.href;
    this.props.copywriting.options.map((option) => {
      if (url.includes(option.url) || jobadCategory === option.category) {
        this.setState({ value: option.description });
      }
    });
  }

  // orderScroll = () => {
  //   if (this.props.hideSearch) return;
  //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  //   const search = document.getElementById('search');
  //   const searchOffsetTop = search && search.offsetTop;
  //   this.setState({ scrollTop, searchOffsetTop });
  // }

  handleChange = (e) => {
    this.setState({ val: e.target.value });
  }

  mobileSearch = () => {
    this.props.handleBlur();
    this.setState({ isFocus: false });

    this.setState({ showSelect: false });
    this.props.copywriting.options.map((option) => {
      if (this.state.value === option.description) {
        if (window.location.href.includes(option.url)) {
          this.props.changeRecruitmentList(true);
          this.props.changejobkeywords(this.state.val);
        }
        navigate(`${option.url}?wd=${this.state.val}`);
      }
    });
    setTimeout(() => {
      const imageBanner = document.getElementById('imageBanner');
      if (imageBanner) {
        const imageBannerHeight = imageBanner.offsetHeight;
        window.scrollTo({
          top: imageBannerHeight,
          behavior: 'smooth',
        });
      }
    }, 200);
  }

  handleKeyUp = (e) => {
    const { keyCode } = e;
    if (keyCode === 38 || keyCode === 40) {
      if (keyCode === 38) {
        this.setState({ index: this.state.index - 1 });
        if (this.state.index < 0) {
          this.setState({ index: this.state.arr.length - 1 });
        }
        // 根据上下键切换，则给表单时面赋不同的值
        e.target.value = this.state.arr[this.state.index + 1];
        this.setState({ val: e.target.value });
      } else {
        this.setState({ index: this.state.index + 1 });
        if (this.state.index >= this.state.arr.length - 1) {
          this.setState({ index: -1 });
        }
        // 根据上下键切换，则给表单时面赋不同的值
        e.target.value = this.state.arr[this.state.index + 1];
        this.setState({ val: e.target.value });
      }
    }
    this.setState({ isFocus: true });
  }

  inputFocus = () => {
    this.setState({ isFocus: true, showSelect: false });
    this.props.handleFocus();
  }

  inputBlur = () => {
    this.setState({ isFocus: false });
    this.props.handleBlur();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.mobileSearch();
    }
  }

  handleMouseEnter = (key, item) => {
    this.setState({ index: key, val: item });
    this.input.value = item;
  }

  handleClick = () => {
    this.mobileSearch();
  }

  selectHandleChange = (item) => { // 选择
    this.setState({
      value: item,
      showSelect: false,
    });
  }

  showSelectDrown = () => { // 选择
    this.setState({
      showSelect: !this.state.showSelect,
    });
  }

  render() {
    const { hideSearch, scrollDirection, currentScrollPosition } = this.props;
    const { options } = this.props.copywriting;
    let searchTheme = 'BlackTheme';
    let themeClass = '';
    let isHome = false;
    if (this.props.location.pathname === '/zh/' || this.props.location.pathname === '/en/' || this.props.location.pathname === '/') {
      isHome = true;
      if ((currentScrollPosition >= 80 || (scrollDirection === 'down' && currentScrollPosition <= 80) || (scrollDirection === 'up' && currentScrollPosition !== 0))) {
        themeClass = 'ScrollBlackTheme';
      } else {
        themeClass = 'BlackTheme';
      }
    } else {
      isHome = false;
      if ((currentScrollPosition >= 80 || (scrollDirection === 'down' && currentScrollPosition <= 80) || (scrollDirection === 'up' && currentScrollPosition !== 0))) {
        themeClass = 'ScrollWhiteTheme';
      } else {
        themeClass = 'WhiteTheme';
      }
    }
    if (this.props.location.pathname === '/zh/' || this.props.location.pathname === '/en/' || this.props.location.pathname === '/') {
      searchTheme = 'Black Theme';
    } else if ((this.props.location.pathname !== '/zh/' && this.props.location.pathname !== '/en/' && this.props.location.pathname !== '/') && (currentScrollPosition >= 80 || (scrollDirection === 'down' && currentScrollPosition <= 80) || (scrollDirection === 'up' && currentScrollPosition !== 0))) {
      searchTheme = 'Black Theme';
    } else {
      searchTheme = 'White Theme';
    }
    const {
      showSelect,
      // isFocus,
      isMobile,
    } = this.state;
    // const searchFocus = isFocus ? 'searchFocus' : '';
    return (
      !hideSearch
        ? <div>
          <div id="search"></div>
          {/* pc端 */}
          {!isMobile && <div className={'w-full flex flex-warp z-[9998] left-1/2 transform -translate-x-1/2 relative'}>
            <div className={`searchWrap ${styles.pcSearchWrap} mt-0 py-[12px] ${themeClass}`}>
              {/* <div className={`search ${searchFocus}`}> */}
              <div className={'search'}>
                {isHome && (options && options.length > 1
                  ? <div className='select mySelect' onClick={() => {
                    this.showSelectDrown();
                  }}>
                    <span>{this.state.value}</span>
                    {<span className="iconfont iconarrow_down selectIcon myIcon" style={{ fontWeight: '700' }}></span>}
                  </div>
                  : <div className={'select mySelect'}>
                    <span>{this.state.value}</span>
                  </div>)}
                <input type="text"
                  ref={(c) => { this.input = c; }}
                  placeholder={this.props.copywriting.placeholder}
                  value={this.state.val}
                  onBlur={this.inputBlur}
                  onFocus={this.inputFocus}
                  onChange={this.handleChange}
                  onKeyUp={this.handleKeyUp}
                  onKeyDown={this.handleKeyDown}
                  className={`form-control myInput ${isHome ? 'ml-[8px]' : ''}`} />
                <span className={'button myButton -ml-[48px] z-[99] flex'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.mobileSearch}>
                  {/* <SearchOutlined className='text-[18px]' /> */}
                  <img style={{ maxWidth: '38%', height: 'auto' }} src={(themeClass === 'WhiteTheme') ? 'https://en.anker-in.com/wp-content/uploads/2025/02/search-white-icon.png' : 'https://en.anker-in.com/wp-content/uploads/2025/02/search-black-icon.png'} />
                </span>
              </div>
              {
                showSelect
                  ? <ul className={'selectDrown mySelectDrown'}
                    onMouseLeave={() => this.setState({ showSelect: false })}
                  >
                    {/* <div className={`h-[48px]`} style={{ opacity: 0 }} onClick={() => {
                      this.setState({
                        showSelect: false,
                      });
                    }}> </div> */}
                    {options && options.map((option, index) =>
                      <li key={index}
                        // style={{ background: currentScrollPosition > 80 ? '#F5F5F7' : '', opacity: currentScrollPosition > 80 ? '1' : '' }}
                        onClick={() => this.selectHandleChange(option.description)}>
                        {option.description}
                      </li>)}
                  </ul>
                  : null
              }
            </div>
          </div>}

          {/* 移动端 */}
          {isMobile && <div className={`searchWrap relative py-3 transform transition-all ${currentScrollPosition <= 0
            ? 'opacity-100 translate-y-0 mt-[0]'
            : 'opacity-0 -translate-y cheeredscale-ycannotfindex-y-[-10px] -mt-[100%]'
          }`}>
            <div className="search">
              {isHome && (options && options.length > 1
                ? <div className={`select ${searchTheme === 'White Theme' ? 'MobileWhiteTheme' : 'MobileBlackTheme'}`} onClick={() => {
                  this.showSelectDrown();
                }}>
                  <span>{this.state.value}</span>
                  {<span className="iconfont iconarrow_down selectIcon" style={{ fontWeight: '700' }}></span>}
                </div>
                : <div className={`select ${searchTheme === 'White Theme' ? 'MobileWhiteTheme' : 'MobileBlackTheme'}`}>
                  <span>{this.state.value}</span>
                </div>)}
              <input
                type="text"
                ref={(c) => { this.input = c; }}
                placeholder={this.props.copywriting.placeholder}
                value={this.state.val}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onKeyUp={this.handleKeyUp}
                onKeyDown={this.handleKeyDown}
                className={`form-control ${searchTheme === 'White Theme' ? 'MobileWhiteTheme' : 'MobileBlackTheme'}`} />
              <span className={`button ${searchTheme === 'White Theme' ? 'MobileWhiteTheme' : 'MobileBlackTheme'}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.mobileSearch}>
                {/* <SearchOutlined className='text-[18px]' /> */}
                <img style={{ maxWidth: '34%', height: 'auto' }} src={(themeClass === 'WhiteTheme') ? 'https://en.anker-in.com/wp-content/uploads/2025/02/search-white-icon.png' : 'https://en.anker-in.com/wp-content/uploads/2025/02/search-black-icon.png'} />
              </span>
            </div>
            {
              showSelect
                ? <ul className={`selectDrown ${searchTheme === 'White Theme' ? 'MobileWhiteTheme' : 'MobileBlackTheme'}`}>
                  {/* <div className={`h-[48px]`} style={{ opacity: 0 }} onClick={() => {
                    this.setState({
                      showSelect: false,
                    });
                  }}> </div> */}
                  {this.props.copywriting.options.map((option, index) =>
                    <li key={index} onClick={() => this.selectHandleChange(option.description)}>
                      {option.description}
                    </li>)}
                </ul>
                : null
            }
          </div>}
        </div>
        : null
    );
  }
}

// 简单的实现数据v-model实现   一定要用defaultValue  如果直接用value 则会将值写死，不会再改变了
export default connect(
  (state) => ({
    jobadCategory: state.app.jobadCategory,
    isChangeList: state.app.isChangeList,
    jobkeywords: state.app.jobkeywords,
  }),
  (dispatch) => ({
    changeRecruitmentList: (open) => dispatch(changeRecruitmentList(open)),
    changejobkeywords: (open) => dispatch(changejobkeywords(open)),
  }),
)(Search);
